<template>
  <div class="places-page">
    <r-container fill class="pa-md-0">
      <r-row>
        <r-col class="col-12 sm-3">
          <r-card class="pa-2 mt-4 card-sticky">
            <r-address
              v-model="address"
              tile
              hide-address
              @update:model-value="go"
            ></r-address>
          </r-card>
        </r-col>
        <r-col class="col-12 sm-9">
          <r-infinite-page v-if="show" url="/home/places/list" :query="query">
            <template v-slot="props">
              <r-container>
                <r-row>
                  <r-col
                    class="col-12 md-6"
                    v-for="item in props.items"
                    :key="item._id"
                  >
                    <r-card
                      class="pa-1"
                      v-animate="{
                        name: 'scaleIn-fade',
                        timing: 'timing-swing',
                        duration: 1,
                      }"
                    >
                      <r-img
                        :src="'/' + item.images[0]"
                        :alt="item.name"
                        :w-p-h="2"
                        auto-size
                      ></r-img>
                      <div class="title my-1 font-weight-bold">
                        {{ item.name }}
                      </div>
                      <div class="subtitle-2 mb-1">
                        {{ $t(item.type) }}
                      </div>
                      <div class="color-success-text py-1 br-md subtitle-1">
                        {{ item.address.state }}-{{ item.address.city }}
                      </div>
                      <div class="text-center my-2">
                        <r-btn
                          class="color-info-text"
                          outlined
                          block
                          :to="{
                            name: 'singlePlace',
                            params: { place_id: item._id },
                          }"
                          >{{ $t("see_more") }}</r-btn
                        >
                      </div>
                    </r-card>
                  </r-col>
                </r-row>
              </r-container>
            </template>
          </r-infinite-page>
        </r-col>
      </r-row>
    </r-container>
  </div>
</template>

<script>
export default {
  name: "places",
  props: {
    state: String,
    city: String,
  },
  data() {
    return {
      show: true,
      address: {
        state: this.state,
        city: this.city,
      },
    };
  },
  watch: {
    query: function () {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 10);
    },
  },
  computed: {
    query() {
      let q = {};

      if (this.state) {
        q["s"] = this.state;
      }
      if (this.city) {
        q["c"] = this.city;
      }
      return q;
    },
  },
  methods: {
    go(e) {
      this.$router.push({
        name: "places",
        params: { state: e.state || "all", city: e.city || "all" },
      });
    },
  },
};
</script>
<style lang="scss">
.places-page {
  .card-sticky {
    position: sticky;
    top: 70px;
  }
}
</style>
